// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactNotifications from "react-notifications";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateBareMetalProjectProvidersSelectionScss from "./CreateBareMetalProjectProvidersSelection.scss";
import * as CreateBareMetalProjectProvidersSelection_SelectProvidersSection from "./CreateBareMetalProjectProvidersSelection_SelectProvidersSection.res.js";

var css = CreateBareMetalProjectProvidersSelectionScss;

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveProjectProvidersSelectionDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
                        }
                        SentryLogger.error1({
                              rootModule: "CreateBareMetalProjectProvidersSelection",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateBareMetalProjectProvidersSelection.Api.persistDraft"
                            }, "SaveBareMetalProjectProvidersSelectionDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                      }));
        }
        
      }));

function submitForm(selectedProvidersIds, context) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveProjectProvidersSelection(selectedProvidersIds), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.BareMetal.GeneralInfo.$$new);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectProvidersSelection",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateBareMetalProjectProvidersSelection.Api.submitForm"
                      }, "SaveBareMetalProjectProvidersSelection::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateProjectProvidersSelection(selectedProvidersIds, projectId), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.BareMetal.GeneralInfo.show(projectId));
          }
          SentryLogger.error1({
                rootModule: "CreateBareMetalProjectProvidersSelection",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateBareMetalProjectProvidersSelection.Api.submitForm"
              }, "UpdateBareMetalProjectProvidersSelection::Error", [
                "Error",
                x._0
              ]);
          $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
        }));
}

function saveDraftProjectAndContinueLater(selectedProvidersIds, context) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveBareMetalDraftProjectProvidersSelection(selectedProvidersIds), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectProvidersSelection",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateBareMetalProjectProvidersSelection.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  } else {
    return $$Promise.wait(Api.updateProjectProvidersSelection(selectedProvidersIds, context.projectId), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateBareMetalProjectProvidersSelection",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateBareMetalProjectProvidersSelection.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateBareMetalProjectProvidersSelection::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
                }));
  }
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater
};

function CreateBareMetalProjectProvidersSelection(props) {
  var userRole = props.userRole;
  var projectStatus = props.projectStatus;
  var userLoginStatus = props.userLoginStatus;
  var providers = props.providers;
  var context = props.context;
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var initialState = React.useMemo((function () {
          var tmp;
          if (context.TAG === "Draft") {
            var selectedProvidersIds = context.selectedProvidersIds;
            tmp = selectedProvidersIds !== undefined ? selectedProvidersIds : (
                userRole === "User" ? Belt_Array.map(Belt_Array.slice(providers, 0, 0), (function (p) {
                          return p.id;
                        })) : Belt_Array.map(providers, (function (p) {
                          return p.id;
                        }))
              );
          } else {
            tmp = context.selectedProvidersIds;
          }
          return {
                  selectedProviders: tmp,
                  userLoginStatus: userLoginStatus
                };
        }), providers);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      selectedProviders: [],
                      userLoginStatus: state.userLoginStatus
                    },
                    _1: (function (param) {
                        persistDraft([
                              [],
                              context
                            ]);
                      })
                  };
          }
          if (action.TAG !== "ToggleProviderSelection") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      selectedProviders: state.selectedProviders,
                      userLoginStatus: action._0
                    },
                    _1: (function (param) {
                        saveDraftProjectAndContinueLater(state.selectedProviders, context);
                      })
                  };
          }
          var provider = action._0;
          var selectedProviders = Js_array.includes(provider.id, state.selectedProviders) ? Js_array.filter((function (id) {
                    return Caml_obj.notequal(id, provider.id);
                  }), state.selectedProviders) : Belt_Array.concat(state.selectedProviders, [provider.id]);
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    selectedProviders: selectedProviders,
                    userLoginStatus: state.userLoginStatus
                  },
                  _1: (function (param) {
                      persistDraft([
                            selectedProviders,
                            context
                          ]);
                    })
                };
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var navigationPreviousControl_1 = function (param) {
    if (context.TAG === "Draft") {
      return Url.visit(Routes_Project.BareMetal.$$new);
    } else {
      return Url.visit(Routes_Project.BareMetal.Configuration.show(context.projectId));
    }
  };
  var navigationPreviousControl = [
    "Back",
    navigationPreviousControl_1,
    "Enabled"
  ];
  var navigationNextControl = React.useMemo((function () {
          var match = state.selectedProviders;
          return [
                  "Next Step",
                  (function () {
                      submitForm(state.selectedProviders, context);
                    }),
                  match.length !== 0 ? "Enabled" : "Disabled"
                ];
        }), [state.selectedProviders]);
  var navigationContinueLaterControl = React.useMemo((function () {
          var match = state.userLoginStatus;
          if (match === "LoggedIn" && projectStatus === "Draft") {
            return [
                    "Save and Continue Later",
                    (function () {
                        saveDraftProjectAndContinueLater(state.selectedProviders, context);
                      })
                  ];
          }
          
        }), [state.selectedProviders]);
  var tmp;
  tmp = context.TAG === "Draft" ? "Step 2: Select Bare Metal Providers" : "Step 2: Edit Bare Metal Providers";
  var tmp$1;
  tmp$1 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: tmp,
                              className: css.heading
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Based on your configurations from the previous step, we've matched your project with the following Bare Metal providers. By default, all providers will be invited to provide solutions and pricing for your bare metal services. You may deselect any providers.",
                              className: css.subHeading
                            }),
                        JsxRuntime.jsx(CreateBareMetalProjectProvidersSelection_SelectProvidersSection.make, {
                              providers: providers,
                              selectedProviders: state.selectedProviders,
                              onDeselectAll: (function (param) {
                                  dispatch("DeselectAll");
                                }),
                              toggleProviderSelection: (function (provider) {
                                  dispatch({
                                        TAG: "ToggleProviderSelection",
                                        _0: provider
                                      });
                                }),
                              userRole: userRole
                            })
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 2 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: navigationNextControl,
                        previous: navigationPreviousControl,
                        continueLater: navigationContinueLaterControl
                      },
                      mobile: props.mobile
                    }),
                tmp$1
              ],
              className: css.container
            });
}

var make = CreateBareMetalProjectProvidersSelection;

export {
  css ,
  Api$1 as Api,
  make ,
}
/* css Not a pure module */
